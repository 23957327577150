import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [autor, setAutor] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://backend.unionskateboard.fr/api/photo/');
        const data = await response.json();
        const lastImages = data.slice(-15).map((entry) => entry.image);
        const imagesAutor = data.slice(-15).map((entry) => entry.artiste);
        setImages(lastImages);
        setAutor(imagesAutor);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Carousel autoPlay infiniteLoop showStatus={true} showThumbs interval={3000}>
      {images.map((image, index) => (
        <div key={index}>
          <LazyLoadImage
            src={image}
            alt={`Image ${index + 1}`}
            effect="blur"
          />
          <p className="legend">{autor[index]}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default Gallery;
