function Session () {
    return (
        <section className="session bg-invert" >
            <div className="container">
            <h2>Nos sessions</h2>
            <p>
                En tant qu'adhérent de l'association Union Skateboard, tu bénéficies d'un accès
                à <strong>nos sessions associatives</strong>, sur nos créneaux, toute l'année* au <strong>skatepark 
                intérieur de Dijon</strong>!
            </p>
            <p>
                Ça se passe au:
            </p>
            <div className="card">
                <strong>2 rue du Général Delaborde</strong> <br />
                21000 Dijon
            </div> 
            <div className="card">
                <ul>
                    <li><strong>Mardi</strong></li>
                    <li>18h00 - 21h00</li>
                    -
                    <li><strong>Vendredi</strong></li>
                    <li>19h00 - 21h00</li>
                </ul>
            </div>   
            <p>
                <small>
                    *: hors vacances scolaire.
                </small>
            </p>
            </div>
        </section>
    )
}

export default Session