import Gallery from "./homeComponent/Gallery"


function About () {
    return (
        <section className="about" id="about">
            <div className="container">
                <h2>A propos</h2>
                <div className="text-box">
                <p>
                Bienvenue sur le site d'Union Skateboard, une association dédiée à la mise en avant et à la pratique du <strong>skateboard à Dijon depuis 2002</strong>.
                </p>
                <p>
                L'USB est née de la passion partagée de skateurs locaux qui souhaitaient créer un espace où les amateurs de skateboard pourraient se réunir, échanger et développer leur passion commune. <strong>Plus de 20 ans après</strong>, notre association est devenue un pilier de la scène du skateboard à Dijon, s'efforcant d'offrir un environnement accueillant et stimulant pour les pratiquants de tous niveaux.
                </p>
                <p>
                Notre objectif principal est de <strong>favoriser la croissance du skateboard</strong> dans notre ville. Par le biais de nos créneaux associatifs, et des évènements que nous organissons, nous offrons l'opportunité aux skateurs dijonnais de montrer leurs talents, de repousser leurs limites et de se connecter avec d'autres passionnés de skateboard. Ces rassemblements créent une atmosphère vibrante, favorisant l'esprit de camaraderie et d'entraide au sein de notre communauté.
                </p>
                <Gallery />
                <p>
                L'USB se veut inclusive et ouverte à tous. Nous accueillons les personnes de tous âges et de tous horizons. Plus qu'un sport, nous croyons fermement que le skateboard est un mode de vie qui permet de dépasser ses limites, qui inspire l'individualité, la persévérance et la liberté d'expression.
                </p>
                <p>
                Nous sommes fiers de notre histoire et de notre engagement envers le skateboard. Nous continuerons à repousser les limites et à favoriser la croissance du skateboard à Dijon. Rejoignez-nous pour faire partie de l'aventure !
                </p>
                <p>
                Skateboardingment vôtre,
                </p>
                <p>
                L'association Union Skateboard (USB)
                </p>
                </div>
               
            </div>
            <breakpoint />
            
        </section>
    )

}

export default About