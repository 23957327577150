import React, {useState, useEffect} from 'react'
import shiftyLogo from '../assets/img/shifty.png';

function Subscribe () {

    const [fichier, setFile] = useState('');



    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://backend.unionskateboard.fr/api/file/`, {
            method: "GET",
            mode: 'cors'
          });
          const data = await response.json();
          const lastEntry = data[data.length - 1];
          const fileUrl = lastEntry.fichier;
    
          // Client-side validation to check if it's a PDF URL
          if (fileUrl.endsWith('.pdf')) {
            setFile(fileUrl);
          } else {
            console.error('Invalid PDF URL:', fileUrl);
            // Handle the error, e.g., display a message to the user
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      fetchData();
    }, []);




    return (
        <section className="subscribe" id="subscribe">
            <div className="container">
            <h2>
                S'inscrire
            </h2>
            <p>
                Prêt à faire partie de <strong>la plus grande commmunauté de skateurs de Dijon</strong>? Rejoins-nous!
            </p>
            <p>
            Télécharges la fiche d’inscription en cliquant sur le lien ci-dessous, ou demande la directement à un référent de session au skatepark intérieur de Dijon aux horaires indiquées ci-dessus.
            </p>
            <a href="https://drive.google.com/file/d/1BaN8NaszGr68YLXSIvaP6BVg_hXQ8m5P/view?usp=drive_link" download className="btn btn-primary">Fiche d'inscription</a>
            <p>
                Tarifs:
            </p>
            <div className="card">
                <ul>
                    <li><strong>+13 ans</strong></li>
                    <li>50,00€</li>
                    -
                    <li><strong>-13 ans</strong></li>
                    <li>35,00€</li>
                </ul>
            </div>
            <p>
                Avec ton adhésion, tu bénéficie également d'une réduction de <strong>-10%</strong> sur ton matériel de skate à <strong>Shifty Boardshop!</strong>
            </p>
            <a className="link" href='https://shiftyboardshop.com'>
              <img src={shiftyLogo} alt='Logo du skateshop Shifty Boardshop' />
            </a>
            </div>
        </section>
    )
}

export default Subscribe