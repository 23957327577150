import React, { useState } from 'react';
import axios from 'axios';
import SubmitedMessage from './Submited';


function Contact() {


    const [formData, setFormData] = useState({
        nom: '',
        mail: '',
        message: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        axios
          .post('https://backend.unionskateboard.fr/submit', formData)
          .then((response) => {
            console.log(response.data);
            setFormData({
                nom: '',
                mail: '',
                message: ''
            })
            alert('Votre message a bien été envoyé!')
            return <SubmitedMessage />
            // Handle successful form submission
          })
          .catch((error) => {
            console.log(error);
            // Handle form submission error
          });
      };

    return (
        <section className="bg-invert contact">
        <div className="container">

        <h2>
            Contact
        </h2>
        <p>
        Nous sommes ravis de te lire ! Remplis le formulaire ci-dessous pour nous contacter et nous te répondrons dans les plus brefs délais. Que ce soit pour des questions, des suggestions, des collaborations ou toute autre demande, n'hésite pas à nous écrire. Nous sommes impatients d'échanger avec toi !
        </p>
        <form action="" id="contact-form" onSubmit={handleSubmit}>

            <div className="row">
                <div className="col-md-6">
                    <label for="nom">Nom *</label>
                    <input className="form-control" type="text" id="name_from" name="nom" value={formData.nom} onChange={handleChange} placeholder="Votre nom"/>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-6">
                    <label for="mail">Email *</label>
                    <input className="form-control" type="email" id="email_from" name="mail" value={formData.mail} onChange={handleChange} placeholder="Votre e-mail"/>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-12">
                    <label for="message">Message *</label>
                    <textarea className="form-control" ea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Votre message"></textarea>
                    <p className="comments">

                    </p>
                </div>  
                <div className="col-md-12">
                    * Ces informations sont requises
                </div>
                <div className="col-md-12">
                    <input type="submit" className="btn btn-primary col-12" value="Envoyer" />
                </div>
                    
            </div>

        </form>
        <p>
        Merci de nous contacter. Nous te répondrons dès que possible.
        </p>
        </div>
    </section>
    )
}

export default Contact