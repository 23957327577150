import React, { useState, useEffect } from 'react';

let headers = {};

function Home() {
  const [titre, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [flyer, setImage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://backend.unionskateboard.fr/api/event/', {
          method: 'GET',
          mode: 'cors',
          headers: headers
        });
        const data = await response.json();
        const lastEntry = data[data.length - 1];

        setTitle(lastEntry.titre);
        setDescription(lastEntry.description);
        setImage(lastEntry.flyer);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="home" id="home">
      <div className="container">
        <div className="row">
          <div className="flexMod flexCol col-md-6">
            <h2>{titre}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
          <div className="flexMod col-md-6">
            <img src={flyer} alt={titre} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
