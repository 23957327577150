import logo from '../assets/img/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


function Header () {

    return (
        <header className='sticky-top' >

            <Navbar sticky='top' collapseOnSelect expand="lg" bg="primary" variant="dark">
            <Container>
                <Navbar.Brand href="#home">
    
                    <img src={logo} width="30" height="30" class="d-inline-block align-top" alt="" />
                    <h1>Union <span className='boldTitle'>Skateboard</span></h1>
                 
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="#home">Accueil</Nav.Link>
                    <Nav.Link href="#about">A propos</Nav.Link>
                    <Nav.Link href="#sessions">Sessions</Nav.Link>
                    <Nav.Link href="#subscribe">Inscriptions</Nav.Link>
                    <Nav.Link href="#contact">Contact</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>

        </header>
      
    )

}

export default Header