import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/style.css';
import Header from './components/Header';
import Home from './components/Home';
import Session from './components/Sessions';
import About from './components/About';
import Subscribe from './components/subscription';
import Contact from './components/Contact';
import Footer from './components/Footer';




function App() {
  return (
    <>

      <Header />

      <breakpoint id="home" />

      <Home />

      <breakpoint id="about" />

      <About />

      <breakpoint id="sessions" />

      <Session />

      <breakpoint id="subscribe" />

      <Subscribe />

      <breakpoint id="contact" />

      <Contact />

      <Footer />

    </>
  );
}

export default App;
