function SubmitedMessage() {
    return(
        <section className="bg-invert">
            <div className="container">
                <p>
                    Votre message a bien été envoyé!
                </p>
            </div>
        </section>
    )
}

export default SubmitedMessage