import instaLogo from '../assets/img/instagram.svg';
import mailLogo from '../assets/img/envelope.svg'

function Footer() {

    return (
    <footer className="">
        <div className="container">

            <h3>
                Union <span className='boldTitle'>Skateboard</span>
            </h3>
            
        </div>
        <div className="container footerMod">
            <div className='sociallist'>
                <ul>
                    <li>
                        <a href='https://www.instagram.com/unionskateboard/'>
                            <img className='socialLogo' src={instaLogo} alt="logo pour la page Instagram d'Union Skateboard"></img>
                        </a>
                    </li>
                    <li>
                        <a href='mailto: contact@unionskateboard.fr'>
                            <img className='socialLogo larger' src={mailLogo} alt="logo pour la page Instagram d'Union Skateboard"></img>
                        </a>
                    </li>
                </ul>
            </div>
            <div className='devAdd'>
                Site réalisé par <a href="https://kilianduclos.com">Kilian Duclos</a>
            </div>
        </div>

    </footer>
    )
}

export default Footer